import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import MenuBar from "./components/MenuBar.jsx";
import SetListener from "./pages/SetListener.jsx";
import AddAccount from "./pages/AddAccount.jsx";
import Home from "./pages/Home.jsx";
import BatchTransaction from "./pages/BatchTransaction.jsx";
import Gather from "./pages/Gather.jsx";
import Distribution from "./pages/Distribution.jsx";
import BatchTransfer from "./pages/BatchTransfer.jsx";
import TokenTaxChecker from "./pages/TokenTaxChecker.jsx";
import Scan from "./pages/Scan.jsx";
import Approve from "./pages/Approve.jsx";

function App() {
  return (
    <Router>
      <MenuBar />
      <Box sx={{ marginTop: "15px" }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/set-listener" element={<SetListener />} />
          <Route path="/add-account" element={<AddAccount />} />
          <Route path="/scan" element={<Scan />} />
          <Route path="/approve" element={<Approve />} />
          <Route path="/distribution" element={<Distribution />} />
          <Route path="/batch_transfer" element={<BatchTransfer />} />
          <Route path="/tax_checker" element={<TokenTaxChecker />} />
          <Route path="/gather" element={<Gather />} />
          <Route
            path="/batch_transaction"
            element={<BatchTransaction />}
          />
        </Routes>
      </Box>
    </Router>
  );
}

export default App;
