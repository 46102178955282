import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";

const SetListener = () => {
  const [listenerName, setListenerName] = useState("");
  const [senderAddress, setSenderAddress] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [methodId, setMethodId] = useState("");
  const [contractAddress, setContractAddress] = useState("");
  const [contractMethodId, setContractMethodId] = useState("");
  const [gasLimit, setGasLimit] = useState("");
  const [amount, setAmount] = useState("0");
  const [isPrepack, setIsPrepack] = useState(true);
  const [gas, setGas] = useState("");
  const [wallets, setWallets] = useState("");
  const [delay, setDelay] = useState(0);
  const [rpc, setRpc] = useState("");
  const [accountGroup, setAccountGroup] = useState(""); // 添加状态

  // 模版预设的处理函数
  const applyTemplate = (template) => {
    if (template === "single") {
      setListenerName("单号模版");
      setContractAddress("0xSingleTemplateAddress");
      setContractMethodId("0xSingleTemplateMethodId");
      setGasLimit("21000");
      setAmount("1");
    } else if (template === "multi-input") {
      setListenerName("多号输入模版");
      setContractAddress("0xMultiInputAddress");
      setContractMethodId("0xMultiInputMethodId");
      setGasLimit("50000");
      setAmount("10");
    } else if (template === "multi-receive") {
      setListenerName("多号获得模版");
      setContractAddress("0xMultiReceiveAddress");
      setContractMethodId("0xMultiReceiveMethodId");
      setGasLimit("80000");
      setAmount("50");
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={3} paddingTop="20px">
      <TextField
        label="设置监听代号"
        value={listenerName}
        onChange={(e) => setListenerName(e.target.value)}
        fullWidth
      />

      <Box display="flex" gap={2}>
        <Button variant="outlined" onClick={() => applyTemplate("single")}>
          单号模版
        </Button>
        <Button variant="outlined" onClick={() => applyTemplate("multi-input")}>
          多号指定输入模版
        </Button>
        <Button
          variant="outlined"
          onClick={() => applyTemplate("multi-receive")}
        >
          多号指定获得模版
        </Button>
      </Box>

      <TextField
        label="设置监听发送交易的钱包地(必填项)"
        value={senderAddress}
        onChange={(e) => setSenderAddress(e.target.value)}
        fullWidth
      />
      <TextField
        label="设置接听接受交易的地址(一般是代币合约地址--可不填)"
        value={receiverAddress}
        onChange={(e) => setReceiverAddress(e.target.value)}
        fullWidth
      />
      <TextField
        label="设置监听MethodId(开盘方法id或者添加流动性方法id--可不填)"
        value={methodId}
        onChange={(e) => setMethodId(e.target.value)}
        fullWidth
      />

      <TextField
        label="设置合约地址(购买代币的合约地址)"
        value={contractAddress}
        onChange={(e) => setContractAddress(e.target.value)}
        fullWidth
      />
      <TextField
        label="设置合约Method ID"
        value={contractMethodId}
        onChange={(e) => setContractMethodId(e.target.value)}
        fullWidth
      />
      <TextField
        label="Gas Limit"
        value={gasLimit}
        onChange={(e) => setGasLimit(e.target.value)}
        fullWidth
      />
      <TextField
        label="Gas Price"
        value={gas}
        onChange={(e) => setGas(e.target.value)}
        fullWidth
      />
      <TextField
        label="数量"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        fullWidth
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isPrepack}
            onChange={(e) => setIsPrepack(e.target.checked)}
          />
        }
        label="是否提前打包"
      />

      <TextField
        label="使用钱包数(默认使用钱包组内的全部钱包)"
        value={wallets}
        onChange={(e) => setWallets(e.target.value)}
        fullWidth
      />
      <TextField
        label="延时（秒）"
        type="number"
        value={delay}
        onChange={(e) => setDelay(e.target.value)}
        fullWidth
      />
      <TextField
        label="使用RPC"
        value={rpc}
        onChange={(e) => setRpc(e.target.value)}
        fullWidth
        multiline
        rows={3}
      />

      <FormControl fullWidth>
        <InputLabel>选择账户组</InputLabel>
        <Select
          value={accountGroup}
          label="选择账户组"
          onChange={(e) => setAccountGroup(e.target.value)}
        >
          <MenuItem value={"group1"}>账户组 1</MenuItem>
          <MenuItem value={"group2"}>账户组 2</MenuItem>
          <MenuItem value={"group3"}>账户组 3</MenuItem>
        </Select>
      </FormControl>

      <Box display="flex" gap={2}>
        <Button variant="contained" color="primary">
          添加
        </Button>
        <Button variant="contained" color="secondary">
          添加到全部节点
        </Button>
      </Box>
    </Box>
  );
};

export default SetListener;
