import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  MenuItem,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";
import { invoke } from "@tauri-apps/api/core";

function TokenTaxChecker() {
  const [tokenAddress, setTokenAddress] = useState("");
  const [poolToken, setPoolToken] = useState("bnb");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("info");

  const handleCheckTax = async () => {
    setLoading(true);
    try {
      const response = await invoke("check_token_tax", {
        tokenAddress,
        poolToken,
      });

      setDialogMessage(
        `Buy Tax: ${response.buyTaxPercentage / 1000}%\nSell Tax: ${
          response.sellTaxPercentage / 1000
        }%`
      );
      setDialogSeverity("success");
    } catch (error) {
      console.log("error", error);
      setDialogMessage("Failed to check token tax. Please try again.");
      setDialogSeverity("error");
    } finally {
      setLoading(false);
      setOpenDialog(true);
    }
  };

  const handleDialogClose = () => setOpenDialog(false);

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Token Tax Checker
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          label="Token Address"
          value={tokenAddress}
          onChange={(e) => setTokenAddress(e.target.value)}
          fullWidth
          variant="outlined"
        />
        <TextField
          select
          label="Pool Token"
          value={poolToken}
          onChange={(e) => setPoolToken(e.target.value)}
          fullWidth
          variant="outlined"
        >
          <MenuItem value="bnb">BNB</MenuItem>
          <MenuItem value="usdt">USDT</MenuItem>
        </TextField>
        <Button
          variant="contained"
          onClick={handleCheckTax}
          disabled={loading}
          startIcon={loading ? <CircularProgress size={20} /> : null}
        >
          {loading ? "Checking..." : "Check Tax"}
        </Button>
      </Box>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>
          {dialogSeverity === "success" ? "Success" : "Error"}
        </DialogTitle>
        <DialogContent>
          <Alert severity={dialogSeverity} sx={{ whiteSpace: "pre-line" }}>
            {dialogMessage}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default TokenTaxChecker;
