import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Typography,
  Container,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";
import server from "../utils/require";

function Gather() {
  const [privateKeys, setPrivateKeys] = useState("");
  const [targetAddress, setTargetAddress] = useState("");
  const [walletInfos, setWalletInfos] = useState([]);
  const [gas, setGas] = useState("");
  const [gasLimit, setGasLimit] = useState("");
  const [tokenType, setTokenType] = useState("bnb");
  const [customTokenAddress, setCustomTokenAddress] = useState("");
  const [selectedTokenAddress, setSelectedTokenAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success");

  const BNB = "0x0000000000000000000000000000000000000000";
  const WBNB = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
  const USDT = "0x55d398326f99059ff775485246999027b3197955";
  const BUSD = "0xe9e7cea3dedca5984780bafc599bd69add087d56";

  // Update selected token address based on tokenType or customTokenAddress
  useEffect(() => {
    if (tokenType === "bnb") {
      setSelectedTokenAddress(BNB);
    } else if (tokenType === "wbnb") {
      setSelectedTokenAddress(WBNB);
    } else if (tokenType === "usdt") {
      setSelectedTokenAddress(USDT);
    } else if (tokenType === "busd") {
      setSelectedTokenAddress(BUSD);
    } else if (tokenType === "custom") {
      setSelectedTokenAddress(customTokenAddress);
    }
  }, [tokenType, customTokenAddress]);

  // Automatically initialize wallet info when privateKeys changes
  useEffect(() => {
    if (privateKeys) {
      handleInitializePrivateKeys();
    }
  }, [privateKeys]);

  const handleInitializePrivateKeys = async () => {
    server.request({
      url: "/wallet/parse_wallets",
      method: "post",
      data: {
        private_keys: privateKeys.split("\n"),
        token_address: selectedTokenAddress,
      },
    })
        .then((response) => {
          const { data } = response.data;
          setWalletInfos(data);
        })
        .catch((error) => {
          console.error("初始化失败:", error);
          showDialog("初始化失败,请重试!", "error");
        });
  };

  const handleGather = async () => {
    setLoading(true);
    server.request({
      url: "/gather",
      method: "post",
      data: {
        private_keys: privateKeys.split("\n"),
        contract_address: selectedTokenAddress,
        gas: parseInt(gas, 10),
        gas_limit: parseInt(gasLimit, 10),
        target_address: targetAddress,
      },
    })
        .then((response) => {
          const { data } = response.data;
          // Update walletInfos with the gather results (success or fail for each address)
          setWalletInfos((prevInfos) =>
              prevInfos.map((info) => {
                const result = data.find((item) => item.address === info.address);
                return result
                    ? { ...info, gatherResult: result.tips }
                    : info;
              })
          );
        })
        .catch((error) => {
          console.error("归集失败:", error);
          showDialog("归集失败,请重试!", "error");
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            handleInitializePrivateKeys();
          }, 3000);
        });
  };

  const showDialog = (message, severity) => {
    setDialogMessage(message);
    setDialogSeverity(severity);
    setOpenDialog(true);
  };

  const handleDialogClose = () => setOpenDialog(false);

  return (
      <Container maxWidth="xl">
        <Typography variant="h4" gutterBottom>
          归集页面
        </Typography>
        <Box display="flex" flexDirection="column" gap={2} sx={{ maxWidth: "100%" }}>
          <TextField
              select
              label="代币类型"
              value={tokenType}
              onChange={(e) => setTokenType(e.target.value)}
              fullWidth
              variant="outlined"
          >
            <MenuItem value="bnb">BNB</MenuItem>
            <MenuItem value="wbnb">WBNB</MenuItem>
            <MenuItem value="usdt">USDT</MenuItem>
            <MenuItem value="busd">BUSD</MenuItem>
            <MenuItem value="custom">自定义代币</MenuItem>
          </TextField>
          {tokenType === "custom" && (
              <TextField
                  label="自定义代币合约地址"
                  value={customTokenAddress}
                  onChange={(e) => setCustomTokenAddress(e.target.value)}
                  fullWidth
                  variant="outlined"
              />
          )}

          <TextField
              label="私钥（每行一个私钥）"
              value={privateKeys}
              onChange={(e) => setPrivateKeys(e.target.value)}
              fullWidth
              multiline
              rows={4}
              variant="outlined"
          />

          <TextField
              label="目标地址"
              value={targetAddress}
              onChange={(e) => setTargetAddress(e.target.value)}
              fullWidth
              variant="outlined"
          />
          <TextField
              label="Gas Price"
              value={gas}
              onChange={(e) => setGas(e.target.value)}
              fullWidth
              variant="outlined"
          />
          <TextField
              label="Gas Limit"
              value={gasLimit}
              onChange={(e) => setGasLimit(e.target.value)}
              fullWidth
              variant="outlined"
          />

          <Button
              variant="contained"
              onClick={handleGather}
              fullWidth
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            {loading ? "归集中..." : "提交归集"}
          </Button>

          {walletInfos.length > 0 && (
              <Box
                  sx={{
                    marginTop: 2,
                    maxHeight: "300px",
                    overflowY: "auto",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    padding: "16px",
                  }}
              >
                {walletInfos.map((info, index) => (
                    <Box
                        key={index}
                        mb={2}
                        p={2}
                        border="1px solid #ddd"
                        borderRadius="8px"
                    >
                      <Typography variant="body1">钱包地址: {info.address}</Typography>
                      <Typography variant="body1">BNB余额: {info.bnb_balance}</Typography>
                      {info.token_symbol && (
                          <Typography variant="body1">
                            {info.token_symbol}余额: {info.token_balance}
                          </Typography>
                      )}
                      {info.gatherResult && (
                          <Typography variant="body1" color="primary">
                            归集结果: {info.gatherResult}
                          </Typography>
                      )}
                    </Box>
                ))}
              </Box>
          )}
        </Box>

        <Dialog open={openDialog} onClose={handleDialogClose} aria-labelledby="alert-dialog-title">
          <DialogTitle id="alert-dialog-title">
            {dialogSeverity === "success"
                ? "成功"
                : dialogSeverity === "warning"
                    ? "警告"
                    : "错误"}
          </DialogTitle>
          <DialogContent>
            <Alert severity={dialogSeverity} sx={{ fontSize: "1.2em" }}>
              {dialogMessage}
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary" autoFocus>
              确定
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
  );
}

export default Gather;
