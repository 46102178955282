import axios from "axios";

const server = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  //baseURL: "https://kc8zxwi7.ailiaili.com/bsc",
  //timeout: 600000000,
  //baseURL: "http://127.0.0.1:8099",
  baseURL: "https://wuguiym.com",
  timeout: 60000,
});

export default server;
