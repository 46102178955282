import React, {useState, useEffect} from "react";
import {
    Box,
    Typography,
    TextField,
    Switch,
    FormControlLabel,
    Button,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    Snackbar,
    Alert,
    MenuItem,
} from "@mui/material";
import server from "../utils/require";

const BatchTransaction = () => {
    const [wallets, setWallets] = useState([]);
    const [privateKeys, setPrivateKeys] = useState("");
    const [showPrivateKeyInput, setShowPrivateKeyInput] = useState(false);
    const [swapAddress, setSwapAddress] = useState(
        "0x10ED43C718714eb63d5aA57B78B54704E256024E"
    );
    const [methodId, setMethodId] = useState(0);
    const [amount, setAmount] = useState("");
    const [tokenAddress, setTokenAddress] = useState("");
    const [gasLimit, setGasLimit] = useState("");
    const [gasPrice, setGasPrice] = useState("");
    const [amountIn, setAmountIn] = useState("");
    const [amountOut, setAmountOut] = useState("");
    const [isBurning, setIsBurning] = useState(false);
    const [burnInterval, setBurnInterval] = useState(3000);
    const [blockNumber, setBlockNumber] = useState(null);
    const [chainTime, setChainTime] = useState(null);
    const [burnIntervalId, setBurnIntervalId] = useState(null);
    const [poolToken, setPoolToken] = useState("WBNB"); // 底池类型
    const [isBuy, setIsBuy] = useState(true); // 买或卖开关

    const [scheduledTime, setScheduledTime] = useState(""); // 定时买入的时间戳
    const [intervalMs, setIntervalMs] = useState(1000); // 间隔时间
    const [repeatCount, setRepeatCount] = useState(1); // 总请求次数
    const [scheduledBuyId, setScheduledBuyId] = useState(null); // 定时买入的计时器ID

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const WBNB = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
    const USDT = "0x55d398326f99059ff775485246999027b3197955";

    const handleImportPrivateKey = () => {
        setShowPrivateKeyInput(true);
    };

    useEffect(() => {
        parse_buy_private_keys().then();
    }, [poolToken]);

    const parse_buy_private_keys = async () => {
        setShowPrivateKeyInput(false);
        const keys = privateKeys
            .split("\n")
            .map((key) => key.trim())
            .filter((key) => key !== "");

        server.request({
            url: "/wallet/parse_wallets",
            method: "post",
            data: {
                private_keys: keys,
                token_address: poolToken === "WBNB" ? WBNB : USDT,
            },
        }).then((response) => {
            const {data} = response.data;
            console.log("data===", data)
            if (data.length > 0) {
                setWallets(data);
                setSnackbarMessage("钱包初始化成功！");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            }
        }).catch((error) => {
            console.error("初始化失败:", error);
            setSnackbarMessage("钱包初始化失败，请重试！");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
    };

    const handleSend = async () => {
        setShowPrivateKeyInput(false);
        const keys = privateKeys
            .split("\n")
            .map((key) => key.trim())
            .filter((key) => key !== "");

        server.request({
            url: "/batch_transaction",
            method: "post",
            data: {
                is_buy:isBuy,
                method_id:methodId,
                pool_token:poolToken === "WBNB" ? WBNB : USDT,
                gas_price:Number.parseInt(gasPrice),
                gas_limit:gasLimit,
                amount_in:amountIn,
                amount_out:amountOut,
                token_address:tokenAddress,
                contract_address:swapAddress,
                private_keys: keys,
            },
        }).then((response) => {
            setSnackbarMessage("单次买入成功！");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        }).catch((error) => {
            console.log("error:",error)
            setSnackbarMessage("买入失败，请重试！");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
    };

    const handleScheduledBuy = () => {
        const targetTime = parseInt(scheduledTime) * 1000;
        const currentTime = Date.now();

        if (isNaN(targetTime) || targetTime <= currentTime) {
            setSnackbarMessage("请输入有效的未来时间戳！");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const delay = targetTime - currentTime;
        const timerId = setTimeout(() => {
            let count = 0;
            const intervalId = setInterval(async () => {
                try {
                    await handleSend();
                    count++;
                    if (count >= repeatCount) {
                        clearInterval(intervalId);
                        setSnackbarMessage("定时批量买入完成！");
                        setSnackbarSeverity("success");
                        setSnackbarOpen(true);
                    }
                } catch (error) {
                    console.error("Scheduled buy failed:", error);
                    setSnackbarMessage("定时批量买入失败，请重试！");
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                    clearInterval(intervalId);
                }
            }, intervalMs);

            setScheduledBuyId(intervalId);
        }, delay);

        setScheduledBuyId(timerId);

        setSnackbarMessage("定时批量买入已设定！");
        setSnackbarSeverity("info");
        setSnackbarOpen(true);
    };

    const cancelScheduledBuy = () => {
        if (scheduledBuyId) {
            clearTimeout(scheduledBuyId);
            clearInterval(scheduledBuyId);
            setScheduledBuyId(null);
            setSnackbarMessage("定时买入已取消！");
            setSnackbarSeverity("info");
            setSnackbarOpen(true);
        }
    };

    const handleApproveWBNB = async () => {
        const keys = privateKeys
            .split("\n")
            .map((key) => key.trim())
            .filter((key) => key !== "");

        server.request({
            url: "/approve/token_wallet/multiple",
            method: "post",
            data: {
                spender: swapAddress,
                token_address: WBNB,
                private_keys: keys,
            }
        }).then(response => {
            setSnackbarMessage("WBNB 授权成功！");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        }).catch(error => {
            console.error("授权失败:", error);
            setSnackbarMessage("WBNB 授权失败，请重试！");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
    };

    const handleApproveUSDT = async () => {
        const keys = privateKeys
            .split("\n")
            .map((key) => key.trim())
            .filter((key) => key !== "");

        server.request({
            url: "/approve/token_wallet/multiple",
            method: "post",
            data: {
                spender: swapAddress,
                token_address: USDT,
                private_keys: keys,
            }
        }).then(response => {
            setSnackbarMessage("USDT 授权成功！");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        }).catch(error => {
            console.error("授权失败:", error);
            setSnackbarMessage("USDT 授权失败，请重试！");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
    };

    const handleApproveToken = async () => {
        const keys = privateKeys
            .split("\n")
            .map((key) => key.trim())
            .filter((key) => key !== "");

        server.request({
            url: "/approve/token_wallet/multiple",
            method: "post",
            data: {
                spender: swapAddress,
                token_address: tokenAddress,
                private_keys: keys,
            }
        }).then(response => {
            setSnackbarMessage("Token 授权成功！");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        }).catch(error => {
            console.error("授权失败:", error);
            setSnackbarMessage("Token 授权失败，请重试！");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        });
    };

    return (
        <Box padding={3} display="flex" flexDirection="column" gap={3}>
            <Typography variant="h4" gutterBottom>
                批量购买
            </Typography>

            {!showPrivateKeyInput && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleImportPrivateKey}
                >
                    导入主钱包私钥
                </Button>
            )}

            {showPrivateKeyInput && (
                <>
                    <TextField
                        label="输入主钱包私钥（每行一个）"
                        value={privateKeys}
                        onChange={(e) => setPrivateKeys(e.target.value)}
                        placeholder="每行一个私钥"
                        multiline
                        rows={4}
                        fullWidth
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={parse_buy_private_keys}
                    >
                        完成
                    </Button>
                </>
            )}

            <List style={{maxHeight: "200px", overflowY: "auto"}}>
                {wallets.map((wallet, index) => (
                    <ListItem key={index} divider>
                        <ListItemText
                            primary={`主钱包地址: ${wallet.address}`}
                            secondary={`BNB余额: ${wallet.bnb_balance} BNB | USDT余额: ${wallet.token_balance} ${wallet.token_symbol}`}
                        />
                    </ListItem>
                ))}
            </List>

            <TextField
                label="购买的Swap地址"
                value={swapAddress}
                onChange={(e) => setSwapAddress(e.target.value)}
                fullWidth
            />

            <TextField
                label="代币合约地址"
                value={tokenAddress}
                onChange={(e) => setTokenAddress(e.target.value)}
                fullWidth
            />

            <FormControlLabel
                control={
                    <Switch
                        checked={methodId === 1}
                        onChange={() => setMethodId((prev) => (prev === 0 ? 1 : 0))}
                    />
                }
                label={methodId === 1 ? "买入固定数量" : "买入金额数量"}
            />

            {/* 底池类型选择 */}
            <TextField
                select
                label="选择底池类型"
                value={poolToken}
                onChange={(e) => setPoolToken(e.target.value)}
                fullWidth
            >
                <MenuItem value="WBNB">WBNB</MenuItem>
                <MenuItem value="USDT">USDT</MenuItem>
            </TextField>

            {/* 买或卖开关 */}
            <FormControlLabel
                control={
                    <Switch checked={isBuy} onChange={() => setIsBuy((prev) => !prev)}/>
                }
                label={isBuy ? "买入" : "卖出"}
            />

            <TextField
                label="Gas Price"
                value={gasPrice}
                onChange={(e) => setGasPrice(e.target.value)}
                fullWidth
            />

            <TextField
                label="Gas Limit"
                value={gasLimit}
                onChange={(e) => setGasLimit(e.target.value)}
                fullWidth
            />

            <TextField
                label="固定买入金额/最大买入花费 (amount_in)"
                value={amountIn}
                onChange={(e) => setAmountIn(e.target.value)}
                fullWidth
            />

            <TextField
                label="最低获得/固定获得 (amount_out)"
                value={amountOut}
                onChange={(e) => setAmountOut(e.target.value)}
                fullWidth
            />

            {/* 定时买入的输入框和按钮 */}
            <TextField
                label="定时买入时间戳（秒）"
                value={scheduledTime}
                onChange={(e) => setScheduledTime(e.target.value)}
                placeholder="输入Unix时间戳（秒）"
                fullWidth
            />
            <TextField
                label="定时买入间隔（毫秒）"
                value={intervalMs}
                onChange={(e) => setIntervalMs(Number(e.target.value))}
                placeholder="输入间隔时间（毫秒）"
                fullWidth
            />
            <TextField
                label="定时买入总次数"
                value={repeatCount}
                onChange={(e) => setRepeatCount(Number(e.target.value))}
                placeholder="输入总请求次数"
                fullWidth
            />
            <Button
                variant="contained"
                color="primary"
                onClick={handleScheduledBuy}
                disabled={!!scheduledBuyId}
            >
                设置定时批量买入
            </Button>

            {scheduledBuyId && (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={cancelScheduledBuy}
                >
                    取消定时买入
                </Button>
            )}

            <Typography variant="body1">
                当前区块号: {blockNumber} | 链上时间: {chainTime}
            </Typography>

            <Box display="flex" gap={2}>
                <Button variant="contained" color="primary" onClick={handleSend}>
                    单次买入
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleApproveWBNB}
                >
                    授权 WBNB
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleApproveUSDT}
                >
                    授权 USDT
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleApproveToken}
                >
                    授权 Token
                </Button>
            </Box>

            {/* Snackbar 提示 */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{vertical: "top", horizontal: "center"}}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{width: "100%"}}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default BatchTransaction;
