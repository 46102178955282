import React, {useState, useEffect} from "react";
import {
    TextField,
    Button,
    MenuItem,
    Typography,
    Container,
    Box,
    IconButton,
    InputAdornment,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import server from "../utils/require";

function Distribution() {
    const [privateKey, setPrivateKey] = useState("");
    const [showPrivateKey, setShowPrivateKey] = useState(false);
    const [walletAddress, setWalletAddress] = useState("");
    const [bnbBalance, setBnbBalance] = useState(null);
    const [tokenBalance, setTokenBalance] = useState("");
    const [publicKey, setPublicKey] = useState("");
    const [amount, setAmount] = useState("");
    const [gas, setGas] = useState("");
    const [gasLimit, setGasLimit] = useState("");
    const [tokenType, setTokenType] = useState("bnb");
    const [customTokenAddress, setCustomTokenAddress] = useState("");
    const [selectedTokenAddress, setSelectedTokenAddress] = useState("");
    const [tokenInfo, setTokenInfo] = useState({symbol: "", decimals: ""});
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogSeverity, setDialogSeverity] = useState("success");
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const BNB = "0x0000000000000000000000000000000000000000";
    const WBNB = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
    const USDT = "0x55d398326f99059ff775485246999027b3197955";
    const BUSD = "0xe9e7cea3dedca5984780bafc599bd69add087d56";

    useEffect(() => {
        if (tokenType === "bnb") {
            setSelectedTokenAddress(BNB);
        } else if (tokenType === "wbnb") {
            setSelectedTokenAddress(WBNB);
        } else if (tokenType === "usdt") {
            setSelectedTokenAddress(USDT);
        } else if (tokenType === "busd") {
            setSelectedTokenAddress(BUSD);
        } else if (tokenType === "custom") {
            setSelectedTokenAddress(customTokenAddress);
        }
    }, [tokenType, customTokenAddress]);

    useEffect(() => {
        if (selectedTokenAddress && !isInitialLoad) {
            handleInitializePrivateKey();
        }
    }, [selectedTokenAddress]);

    const handleInitializePrivateKey = async () => {
        server.request({
            url: "/wallet/parse_wallet",
            method: "post",
            data: {
                private_key: privateKey,
                token_address: selectedTokenAddress
            }
        }).then(response => {
            const {data} = response.data;
            console.log("data====", data);
            setWalletAddress(data.address);
            setBnbBalance(data.bnb_balance);
            setTokenInfo({symbol: data.token_symbol, decimals: data.token_decimals})
            setTokenBalance(data.token_balance);
        }).catch(error => {
            console.error("初始化失败:", error);
            showDialog("初始化失败,请重试!", "error");
        });
    };

    const handleDistribution = async () => {
        server.request({
            url: "/distribution",
            method: "post",
            data: {
                private_key: privateKey,
                public_keys: publicKey.split("\n"),
                amount: parseFloat(amount),
                gas: parseInt(gas, 10),
                gas_limit: parseInt(gasLimit, 10),
                token_address: selectedTokenAddress,
            },
        }).then((response) => {
            showDialog("分发成功！", "success");
        }).finally(() => {
            setLoading(false);
            setTimeout(() => {
                handleInitializePrivateKey(); // 延迟调用刷新余额
            }, 3000);
        });
    };

    const toggleShowPrivateKey = () => setShowPrivateKey(!showPrivateKey);

    const showDialog = (message, severity) => {
        setDialogMessage(message);
        setDialogSeverity(severity);
        setOpenDialog(true);
    };

    const handleDialogClose = () => setOpenDialog(false);

    const handleInitializeClick = () => {
        setIsInitialLoad(false);
        handleInitializePrivateKey();
    };

    return (
        <Container maxWidth="xl">
            <Typography variant="h4" gutterBottom>
                分发页面
            </Typography>
            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                sx={{maxWidth: "100%"}}
            >
                <TextField
                    select
                    label="代币类型"
                    value={tokenType}
                    onChange={(e) => setTokenType(e.target.value)}
                    fullWidth
                    variant="outlined"
                >
                    <MenuItem value="bnb">BNB</MenuItem>
                    <MenuItem value="wbnb">WBNB</MenuItem>
                    <MenuItem value="usdt">USDT</MenuItem>
                    <MenuItem value="busd">BUSD</MenuItem>
                    <MenuItem value="custom">自定义代币</MenuItem>
                </TextField>
                {tokenType === "custom" && (
                    <Box display="flex" alignItems="center" gap={1}>
                        <TextField
                            label="自定义代币合约地址"
                            value={customTokenAddress}
                            onChange={(e) => setCustomTokenAddress(e.target.value)}
                            fullWidth
                            variant="outlined"
                        />
                    </Box>
                )}

                <TextField
                    label="私钥"
                    type={showPrivateKey ? "text" : "password"}
                    value={privateKey}
                    onChange={(e) => setPrivateKey(e.target.value)}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={toggleShowPrivateKey} edge="end">
                                    {showPrivateKey ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button variant="outlined" onClick={handleInitializeClick}>
                    初始化
                </Button>
                {walletAddress && (
                    <Box sx={{marginTop: 2}}>
                        <Typography variant="body1">钱包地址: {walletAddress}</Typography>
                        <Typography variant="body1">BNB余额: {bnbBalance}</Typography>
                        {tokenInfo.symbol !== "" && (
                            <Typography variant="body1">
                                {tokenInfo.symbol}余额: {tokenBalance}
                            </Typography>
                        )}
                    </Box>
                )}
                <TextField
                    label="分发地址公钥（多行输入，每行一个地址）"
                    value={publicKey}
                    onChange={(e) => setPublicKey(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                />
                <TextField
                    label="分发数额"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    label="Gas Price"
                    value={gas}
                    onChange={(e) => setGas(e.target.value)}
                    fullWidth
                    variant="outlined"
                />
                <TextField
                    label="Gas Limit"
                    value={gasLimit}
                    onChange={(e) => setGasLimit(e.target.value)}
                    fullWidth
                    variant="outlined"
                />
                <Button
                    variant="contained"
                    onClick={handleDistribution}
                    fullWidth
                    disabled={loading}
                    startIcon={loading ? <CircularProgress size={20}/> : null}
                >
                    {loading ? "分发中..." : "提交分发"}
                </Button>
            </Box>

            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogSeverity === "success"
                        ? "成功"
                        : dialogSeverity === "warning"
                            ? "警告"
                            : "错误"}
                </DialogTitle>
                <DialogContent>
                    <Alert severity={dialogSeverity} sx={{fontSize: "1.2em"}}>
                        {dialogMessage}
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary" autoFocus>
                        确定
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default Distribution;
