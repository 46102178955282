import React, {useState, useEffect} from "react";
import {
    TextField,
    Button,
    MenuItem,
    Typography,
    Container,
    Box,
    InputAdornment,
    IconButton,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert,
} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import server from "../utils/require";

function Approve() {
    const WBNB = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
    const USDT = "0x55d398326f99059ff775485246999027b3197955";
    const BUSD = "0xe9e7cea3dedca5984780bafc599bd69add087d56";

    const [privateKey, setPrivateKey] = useState("");
    const [showPrivateKey, setShowPrivateKey] = useState(false);
    const [targetAddress, setTargetAddress] = useState("");
    const [walletInfo, setWalletInfo] = useState(null);
    const [tokenType, setTokenType] = useState("wbnb");
    const [customTokenAddress, setCustomTokenAddress] = useState("");
    const [selectedTokenAddress, setSelectedTokenAddress] = useState(WBNB);
    const [loading, setLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogSeverity, setDialogSeverity] = useState("success");
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [authorizationHash, setAuthorizationHash] = useState(""); // 添加此状态

    useEffect(() => {
        if (tokenType === "wbnb") {
            setSelectedTokenAddress(WBNB);
        } else if (tokenType === "usdt") {
            setSelectedTokenAddress(USDT);
        } else if (tokenType === "busd") {
            setSelectedTokenAddress(BUSD);
        } else if (tokenType === "custom") {
            setSelectedTokenAddress(customTokenAddress);
        }
    }, [tokenType, customTokenAddress]);

    useEffect(() => {
        if (selectedTokenAddress && !isInitialLoad) {
            handleInitializePrivateKeys();
        }
    }, [selectedTokenAddress]);

    const handleInitializePrivateKeys = async () => {
        server.request({
            url: "/wallet/parse_wallet",
            method: "post",
            data: {
                private_key: privateKey,
                token_address: selectedTokenAddress
            }
        }).then(response => {
            const {data} = response.data;
            setWalletInfo(data);
        }).catch(error => {
            console.error("初始化失败:", error);
            showDialog("初始化失败,请重试!", "error");
        });
    };

    const handleApprove = async () => {
        setLoading(true);
        server.request({
            url: "/approve/token_wallet/single",
            method: "post",
            data: {
                spender: targetAddress,
                token_address: selectedTokenAddress,
                private_key: privateKey,
            }
        }).then(response => {
            const {message} = response.data;
            setAuthorizationHash(message); // 设置授权哈希
            setLoading(false);
            console.log("message", message);
            showDialog("授权成功", "success");
        }).catch(error => {
            console.error("授权失败:", error);
            setLoading(false);
            showDialog("授权失败,请重试!", "error");
        });
    };

    const toggleShowPrivateKey = () => setShowPrivateKey(!showPrivateKey);

    const showDialog = (message, severity) => {
        setDialogMessage(message);
        setDialogSeverity(severity);
        setOpenDialog(true);
    };

    const handleDialogClose = () => setOpenDialog(false);

    const handleInitializeClick = () => {
        setIsInitialLoad(false);
        handleInitializePrivateKeys();
    };

    return (
        <Container maxWidth="xl">
            <Typography variant="h4" gutterBottom>
                授权页面
            </Typography>
            <Box
                display="flex"
                flexDirection="column"
                gap={2}
                sx={{maxWidth: "100%"}}
            >
                <TextField
                    select
                    label="代币类型"
                    value={tokenType}
                    onChange={(e) => setTokenType(e.target.value)}
                    fullWidth
                    variant="outlined"
                >
                    <MenuItem value="wbnb">WBNB</MenuItem>
                    <MenuItem value="usdt">USDT</MenuItem>
                    <MenuItem value="busd">BUSD</MenuItem>
                    <MenuItem value="custom">自定义代币</MenuItem>
                </TextField>
                {tokenType === "custom" && (
                    <Box display="flex" alignItems="center" gap={1}>
                        <TextField
                            label="自定义代币合约地址"
                            value={customTokenAddress}
                            onChange={(e) => setCustomTokenAddress(e.target.value)}
                            fullWidth
                            variant="outlined"
                        />
                    </Box>
                )}

                <TextField
                    label="私钥"
                    type={showPrivateKey ? "text" : "password"}
                    value={privateKey}
                    onChange={(e) => setPrivateKey(e.target.value)}
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={toggleShowPrivateKey} edge="end">
                                    {showPrivateKey ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <Button variant="outlined" onClick={handleInitializeClick}>
                    初始化
                </Button>
                {walletInfo != null && (
                    <Box
                        mb={2}
                        p={2}
                        border="1px solid #ddd"
                        borderRadius="8px"
                    >
                        <Typography variant="body1">
                            钱包地址: {walletInfo.address}
                        </Typography>
                        <Typography variant="body1">
                            BNB余额: {walletInfo.bnb_balance}
                        </Typography>
                        {walletInfo.token_symbol && (
                            <Typography variant="body1">
                                {walletInfo.token_symbol}余额: {walletInfo.token_balance}
                            </Typography>
                        )}
                    </Box>
                )}

                <TextField
                    label="目标地址"
                    value={targetAddress}
                    onChange={(e) => setTargetAddress(e.target.value)}
                    fullWidth
                    variant="outlined"
                />

                <Button
                    variant="contained"
                    onClick={handleApprove}
                    fullWidth
                    disabled={loading}
                    startIcon={loading ? <CircularProgress size={20}/> : null}
                >
                    {loading ? "授权中..." : "提交授权"}
                </Button>

                {/* 显示授权成功的哈希值 */}
                {authorizationHash && (
                    <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                        授权哈希: {authorizationHash}
                    </Typography>
                )}
            </Box>

            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogSeverity === "success"
                        ? "成功"
                        : dialogSeverity === "warning"
                            ? "警告"
                            : "错误"}
                </DialogTitle>
                <DialogContent>
                    <Alert severity={dialogSeverity} sx={{fontSize: "1.2em"}}>
                        {dialogMessage}
                    </Alert>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary" autoFocus>
                        确定
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default Approve;
