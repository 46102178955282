import React, { useEffect, useState } from "react";
import { invoke } from "@tauri-apps/api/core";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";

const Home = () => {
  const [stencilDetails, setStencilDetails] = useState([]);

  useEffect(() => {}, []);

  return (
    <Box padding={3}>
      <Typography variant="h4" gutterBottom>
        监听列表
      </Typography>
      <List>
        {stencilDetails.map((stencil, index) => (
          <ListItem key={index} divider>
            <ListItemText
              primary={stencil.name}
              secondary={
                <>
                  <div>合约地址: {stencil.contract_address}</div>
                  <div>方法ID: {stencil.method_id}</div>
                  <div>Gas限制: {stencil.gas_limit}</div>
                  <div>提前Gas: {stencil.pre_gas ? "是" : "否"}</div>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Home;
