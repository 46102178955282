import React, { useState } from "react";
import { Box, TextField, Button } from "@mui/material";

const AddAccount = () => {
  const [group, setGroup] = useState("");
  const [privateKeys, setPrivateKeys] = useState("");

  const handleSubmit = () => {
    const privateKeyArray = privateKeys
      .split("\n")
      .map((key) => key.trim())
      .filter((key) => key !== "");

    console.log("分组：", group);
    console.log("私钥数组：", privateKeyArray);
  };

  const handleAddToAllNodes = () => {
    const privateKeyArray = privateKeys
      .split("\n")
      .map((key) => key.trim())
      .filter((key) => key !== "");

    console.log("分组：", group);
    console.log("私钥数组：", privateKeyArray);
  };

  return (
    <Box display="flex" flexDirection="column" gap={3} paddingTop="20px">
      <TextField
        label="分组"
        value={group}
        onChange={(e) => setGroup(e.target.value)}
        fullWidth
      />
      <TextField
        label="输入私钥（每行一个）"
        value={privateKeys}
        onChange={(e) => setPrivateKeys(e.target.value)}
        fullWidth
        multiline
        rows={4}
        placeholder="每行一个私钥"
      />

      <Box display="flex" gap={2}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          添加
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleAddToAllNodes}
        >
          添加到所有节点
        </Button>
      </Box>
    </Box>
  );
};

export default AddAccount;
